.mobile-menu
{
  width: 100%;
  height: 100vh;
  background-color: $light;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 100000;
  text-align: center;

  transition: all 0.2s ease-in-out;

  &.active
  {
    left: 0;
  }

  .logo-mobile
  {
    width: 100%;
    margin-top: 20px;
    max-width: 300px;
    display: inline-block;
  }

  .mobile-social {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .icon-share
  {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    transition: all 0.2s ease-in-out;

    background-size: cover;
    background-repeat: no-repeat;

    &.instagram {
      background-image: url('../images/instagram.svg')
    }

    &.facebook {
      background-image: url('../images/facebook.svg')
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover
    {
      opacity: .7;
    }
  }

  nav
  {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 15vw;

    @media (max-width: 414px) {
      transform: translate(0, -42%);
    }

    a
    {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      transition: all 0.2s ease-in-out;

      @media (max-width: 414px) {
        padding: 10px 40px;
      }

      &:hover
      {
        background-color: $colorAccent2;
      }

      &.active
      {
        border-color: $colorAccent1;
        background: rgba($colorAccent1, 0.2);
      }
    }
  }
  form {
    input {
      display: block;
      width: 100%;
      border: 2px solid $colorAccent1Lighter;
      margin-bottom: 10px;
      padding: 8px 13px 8px 50px;
      font-size: 14px;
      border-radius: 100px;
      box-sizing: border-box;
      color: $darkGrey;
      background: url('../images/search-light.svg') no-repeat left 15px center / 15px;
      outline: none;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba($darkGrey, .75);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba($darkGrey, .75);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba($darkGrey, .75);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba($darkGrey, .75);
      }
    }
  }
}

.switch-mobile
{
  width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: $colorAccent1;
  border-radius: 30px;
  position: fixed;
  z-index: 10000000;
  right: 20px;
  bottom: 20px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(50,50,50,0.5);
  display: none;
  .open
  {
    width: 22px;
    margin-top: 22px;
    display: inline-block;
    div
    {
      width: 100%;
      height: 4px;
      background-color: #fff;
      margin-bottom: 2px;
    }
  }
  .close
  {
    display: none;
    p
    {
      font-size: 25px;
      margin-top: 18px;
      font-family: $fontTitle;
      color: #fff;
    }
  }
}
@media all and (max-width: 1260px)
{
  .switch-mobile
  {
    display: block;
  }
}
