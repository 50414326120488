.site-content {
  padding-top: 65px;
  @media all and (max-width: 1260px) {
    padding-top: 50px;
  }
}

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  width: 100%;

  background: $white;
  border-bottom: 1px solid #eee;

  @extend %clearfix;

  transition: all 0.2s ease-in-out;

  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 30px 8px;
    display: flex;
  }

  .logo {
    position: absolute;
    top: 11px;
    left: 63px;

    @media (max-width: 1300px) {
      top: 20px;
      left: 0;
    }

    img {
      width: 263px;

      @media (max-width: 1300px) {
        width: 230px;
      }
    }
  }

  .right {
    margin-left: auto;


    a {
      display: inline-block;
      padding: 20px 15px 0;

      @extend .text-subtitle;

      color: $darkGrey;
      text-transform: uppercase;
      transition: opacity .3s ease-in-out;

      @media all and (max-width: 1500px) {
        padding: 5px 5px;
      }

      @media all and (max-width: 1260px) {
        display: none;
      }

      &:hover,
      &:active {
        opacity: .7;
      }

      &.search {
        padding: 20px 15px 0;

        @media screen and (max-width: 1024px) {
          padding: 0 15px;
        }
      }

      &.lang-switcher {
        @extend .text-caption;
      }

      &.cta {
        float: none;
        margin-left: 10px;

        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        text-transform: none;
        text-align: center;

        padding: 14px 40px;
        background-color: $colorAccent1;
        color: #fff;

        &:hover {
          background-color: $colorAccent1;
        }
      }
    }

  }

  .icons {
    display: inline-block;

    a {
      font-size: 125%;
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }
      &:last-child {
        padding-left: 10px;
        padding-right: 0;
      }
    }

    i {
      position: relative;
      top: 3px;
    }
  }

  .tel {
    display: none;
    position: relative;
    top: 50%;
    transform: translate(0, 50%);
    float: left;
    margin-right: 25px;
    font-size: 18px;
    font-weight: 500;
    font-family: $fontTitle;

    &.mobile {
      display: none;

    }
  }

  .site-btn {
    display: none;
    float: left;
  }

  &.follow {
    background: $white;

    .site-btn {
      border-color: $colorAccent1;
      color: $white;
      background: $colorAccent1;
      &:hover {
        background: $white;
        color: $colorAccent1;
      }
    }

    .right {
      padding-top: 0;
    }

  }

  .search {
    width: 50px;
    height: 20px;
    padding: 0 13px;
    cursor: pointer;

    @media screen and (max-width: 1350px) {
      padding: 25px 0 25px 10px;
    }

    img {
      width: 100%;
      margin-bottom: -5px;

      &.icon_close {
        display: none;
      }
    }
  }

  #search-input {
    position: absolute;
    right: 380px;
    top: 5px;
    width: 0;
    opacity: 0;
    transition: all ease-out .2s;

    &.open {
      width: 460px;
      opacity: 1;

      @media screen and (max-width: 1080px) {
        width: 420px;
      }
      @media screen and (max-width: 1024px) {
        right: 350px;
      }

      @media screen and (max-width: 980px) {
        width: 400px;
      }

      @media screen and (max-width: 940px) {
        width: 350px;
      }

      @media screen and (max-width: 890px) {
        width: 300px;
      }

      @media screen and (max-width: 850px) {
        display: none;
      }
    }

    input {
      outline: none;
      float: left;
      width: calc(100% - 40px);
      padding: 10px 10px 5px 0;
      border: 0;
      font-size: 25px;

      @-moz-document url-prefix() {
        width: calc(100% - 53px);
      }
    }

    button {
      width: 30px;
      height: 30px;
      float: left;
      border: none;
      font-size: 18px;
      padding: 5px 10px;
      line-height: 25px;
      margin-top: 10px;
      cursor: pointer;
      background: $colorAccent1 url('../images/search-light.svg') no-repeat center center / 15px;
    }
  }

  @media all and (max-width: 1024px) {

    &,
    &.follow {
      background: $white;
      border-bottom: 1px solid #eee;

      .tel {
        color: $grey;
      }

      .site-btn {
        border-color: $colorAccent1;
        color: $colorAccent1;
        background: $white;
        &:hover {
          border-color: $colorAccent1;
          background: $colorAccent1;
          color: $white;
        }
      }

      .right {
        padding-top: 0;
      }
    }

  }

  @media all and (max-width: 1260px) {
    .right {
      a {
        display: none;

        &.cta {
          display: inline-block;
        }
      }
    }

  }

  @media all and (max-width: 529px) {
    &,
    &.follow {

      .site-btn {
        margin-top: 2px;
        padding: 13px 15px;
        font-size: 12px;
      }

      .tel {
        transform: translate(0, 41%);
        font-size: 14px;
        &.mobile {
          transform: translate(0, 9%);
        }
      }
    }
  }

  @media all and (max-width: 469px) {

    .right {
      a {
        display: none;
        &.cta {
          display: inline-block;
          height: 50px;
          padding: 12px 15px 0;
          font-size: 12px;
          background-size: 7px;
        }
      }
    }

    &,
    &.follow {

      .tel {
        display: none;

      }

      .site-btn {
        display: none;
      }
    }
  }

}
