.bloc-arguments-2{
  padding: 100px 50px;
  text-align: center;
  background: $colorAccent4;
  position: relative;
  z-index: 1000;

  &.no-padding
  {
    padding: 0;
  }

  .site-btn-center
  {
    .color-light
    {
      color: #fff !important;
    }
  }

  .title3{
    @extend .title4;
    color: $light;
    max-width: 540px;
    margin: auto;
  }

  .arguments{
    padding: 50px 0;

    .site-btn-center {
      margin-top: 50px;
      padding: 0;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }

  .bloc-list.one-third.argument{
    text-align: center;
    margin: 0 30px 0 0;

    width: calc((100% / 3) - 20px);

    &:last-of-type {
      margin-right: 0;
    }

  }

  &.medium
  {
    background-color: #f1f1f1;
  }

  &.dark{
    background-image: url('../images/dark-background.jpg');
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    @media all and (max-width: 1024px) {
      background-attachment: scroll;
    }
    .title3
    {
      color: #fff;
    }
    .bloc-list
    {
      .content
      {
        .title4
        {
          color: $colorAccent3;
        }

        .title3.text
        {
          color: #fff;
        }
      }
    }
  }


  @media all and (max-width: 1024px) {
    padding: 40px 20px;
  }

  @media all and (max-width: 1119px) {

    .bloc-list{
      .content{
        padding: 20px 10%;
      }
    }

  }

  @media all and (max-width: 599px) {

    .arguments
    {
      padding: 0 0;
    }
  }

}

.bloc-list
{
  .tag-wrapper {
    max-width: 385px;
    margin: auto;
    position: absolute;

    p.tag {
      position: absolute;
      top: 18px;
      left: -10px;
      width: 144px;
      min-height: 32px;
      text-align: center;
      padding: 7px 0 5px;
      z-index: 100;
      margin: 0;
      display: inline-block;

      @extend .text-subtitle;

      background-color: $colorAccent2;
      color: $colorAccent1;
      box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.25);

      &.new-tag {
        background-color: $colorAccent5;
        color: $colorAccent1;

        &:after {
          border-top: 16px solid $colorAccent5;
          border-bottom: 16px solid $colorAccent5;
          border-left: 7px solid $colorAccent5;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 144px;
        bottom: 0;
        width: 0;
        border-right: 7px solid transparent;
        border-top: 16px solid $colorAccent2;
        border-bottom: 16px solid $colorAccent2;
        border-left: 7px solid $colorAccent2;
      }
    }
  }

  position: relative;

  .top
  {
    overflow: hidden;
    position: relative;
    background-color: $colorAccent1;

    .preparation
    {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;

      z-index: 100;
      margin: 0;
      display: inline-block;
      background-color: rgba($colorAccent1, .9);
      padding: 10px 5px;

      @extend .text-caption;
      text-align: center;
      color: #fff;
      a{
        color: $colorAccent3;
      }
    }

    .portions
    {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 50%;

      z-index: 100;
      margin: 0;
      display: inline-block;
      background-color: rgba($colorAccent1, .9);
      padding: 10px 5px;
      @extend .text-caption;
      color: #fff;
      text-align: center;
    }

    .play
    {
      width: 100%;
      max-width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      z-index: 100;
    }

    .thumb
    {
      display: block;
      width: 100%;
      height: auto;
      transform: scale(1.01);
      transition: all 0.5s ease-in-out;
      opacity: 1;

      &:hover
      {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
  }

  .content
  {
    padding: 20px 15% 0 15%;

    @media all and (max-width: 560px){
      padding: 20px 15% 20px 15%;
    }

    .title4{
      padding: 0 0 10px 0;
      line-height: 1;
      max-width: 330px;
      margin: 0 auto;
      font-size: 13px;
      font-weight: 400;
      color: $colorAccent3;
      letter-spacing: 1px;
      text-align: center;
    }

    .title3.text{
      font-size: 16px;
      line-height: 1.5;
      font-family: $fontTitleBold;
      font-weight: 100;
      letter-spacing: 2px;
      text-align: center;
      color: $colorAccent1;
    }
  }
}

.aside-list
{
  position: relative;
  z-index: 1000;
  display: table;
  width: 100%;

  @media all and (max-width: 900px){
    display: block;
  }

  .sub-menu
  {
    display: table-cell;
    width: 25%;
    background-color: #fff;
    vertical-align: top;

    @media all and (max-width: 900px){
      display: block;
      width: 100%;
    }

    .show-hide-categories
    {
      width: 100%;
      padding: 30px 25px;
      background-color: $colorAccent1;
      cursor: pointer;
      display: none;

      @media all and (max-width: 900px){
        display: block;
      }

      @extend %clearfix;

      p
      {
        margin: 0;
        padding: 0;

        @extend .text-subtitle;
        color: $white;

        display: inline-block;
      }

      .name
      {
        float: left;
      }

      .plus
      {
        float: right;
        font-size: 24px;
      }

      .moins
      {
        float: right;
        font-size: 24px;
        display: none;
      }

      &.open
      {
        .plus
        {
          display: none;
        }
        .moins
        {
          display: block;
        }
      }


    }

    .sub-menu-link-wrap
    {
      @media all and (max-width: 900px){
        display: none;
      }
    }

    .sub-menu-link
    {
      width: 100%;
      display: block;
      padding: 30px 25px;
      border-bottom: 1px solid $lightGrey;

      @extend .text-subtitle;
      color: $darkerGrey;

      transition: all 0.2s ease-in-out;

      background-image: url('../images/arrow-black.png');
      background-position: 93% center;
      background-repeat: no-repeat;
      background-size: 8px;

      @media all and (max-width: 900px){
        background-position: 95% center;
      }

      &.active
      {
        background-color: $colorAccent1;
        color: $white;
        background-image: url('../images/arrow.png');

      }

      &:hover
      {
        background-image: url('../images/arrow.png');
        background-color: rgba($colorAccent1, .7);
        color: $white;
      }
    }
  }



  .list-wrap
  {
    display: block;
    width: 100%;
    padding: 30px;
    background: $colorAccent3;

    @extend %clearfix;

    .list-container {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1190px;
      margin: 0 auto;
    }

    .title5 {
      color: $darkGrey;
    }

    .bloc-list
    {
      display: inline-block;
      width: calc(33.33% - (60px / 2));
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      @media all and (max-width: 1023px){
        width: calc(50% - 30px);

        &:nth-child(3n + 3) {
          margin-right: 30px;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      @media all and (max-width: 560px){
        width: 100%;
        margin-right: 0;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }
}

.listWrap{
  display: flex;
  flex-wrap: wrap;

  @extend %clearfix;

  &.centered{
    justify-content: center;
  }

  .content {
    .text-subtitle {
      margin-bottom: 5px;
      color: $grey;
    }
  }

  .title5 {
    color: $darkGrey;
  }

  .text-subtitle,
  .title5 {
    text-align: center;
  }

  .list-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

#bloc-recettes.bloc-arguments-2 {
  background: $colorAccent4;
  .title3 {
    @extend .title4;
    text-align: center;
    margin: auto;
    color: $white;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}

.grid-recettes.bloc-arguments-2 {
  background: $colorAccent3;

  .title3 {
    @extend .title4;
    text-align: center;
    margin: auto;
    color: $black;
  }

  .bloc-list.one-third.argument {
    width: calc((100% / 3) - 30px);
    margin-bottom: 30px;

    @media (max-width: 1024px) {
      width: calc((100% / 2) - 30px);
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 auto 30px;
    }
  }
}

#bloc-recettes {
  .listWrap {
    .title5 {
      color: $white;
    }
  }
}

/*
.hero-recette {
  position: relative;
  z-index: 1000;
  overflow: hidden;
  min-height: 400px;
  background: url('../images/hero.jpg') $light no-repeat;
  background-position: center center;
  background-size: cover;

  .overlay-opacity
  {
    width: 100%;
    min-height: 400px;
    background-color: $colorAccent1;
    opacity: 0.4;
  }

  .credit
  {
    color: #fff;
    font-size: 10px;
    margin: 0;
    padding: 0;

    position: absolute;
    right: 15px;
    bottom: 15px;

    z-index: 100;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .title1 {
      font-size: 42px;
      margin: 0 auto;
      max-width: 740px;
      color: #fff;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 2px;
      font-family: $fontMainTitle;
      position: relative;
      top: 30px;

      span {
        color: $colorAccent1;
      }

    }

    .tool-bar
    {
      display: inline-block;
      list-style-type: none;
      position: relative;
      top: 80px;

      border-radius: 3px;
      border: 2px solid $colorAccent2;

      margin: 0;
      padding: 0;

      @media all and (max-width: 900px){
        top: 30px;
        max-width: 300px;
      }

      @extend %clearfix;

      li
      {
        display: inline-block;
        float: left;
        min-width: 225px;
        text-align: center;

        border-right: 2px solid $colorAccent2;

        padding: 5px 15px;

        @media all and (max-width: 900px){
          width: 100%;
          border-right:0;
          border-bottom: 2px solid $colorAccent2;
          &:last-child
          {
            border-bottom: 0;
          }
        }

        &:last-child
        {
          border-right: 0;
        }


        @extend %clearfix;

        img
        {
          display: inline-block;
          width: auto;
          height: 30px;
          position: relative;
          top: 10px;
          &.portions-icon
          {
            height: 23px;
            top: 6px;
          }
        }

        p
        {
          display: inline-block;
          color: #fff;
          font-family: $fontTitle;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-left: 10px;
        }
      }
    }


  }

  @media all and (max-width: 767px) {

    .content {

      .title1 {
        font-size: 24px;
        line-height: 1.5;
        padding: 0;
        margin-bottom: 20px;
        max-width: 540px;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .title1 {
        font-size: 22px;
        line-height: 1.2;
      }

    }

  }

}
*/

.recette-wrap
{
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 1000;

  .recette-data
  {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;
    background-color: #fff;


    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    line-height: 30px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      @extend .main-text;
      margin-bottom: 20px;
      color: $darkGrey;
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .ingredients
    {
      width: 40%;
      display: inline-block;
      float: left;

      @media all and (max-width: 767px){
        width: 100%;
        margin-bottom: 20px;
      }
      .recette-sub-header{
        h2{
          color: $white;
          margin: 0;
        }
      }
      ul
      {
        background-color: $lighterGrey;
        padding: 30px 30px 10px 60px;
        margin: 0;
        list-style: none;

        li
        {
          position: relative;
          a
          {
            color: $darkGrey;
          }

          .container {
            display: block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default checkbox */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 5px;
            left: -30px;
            height: 20px;
            width: 20px;
            background-color: white;
            border-radius: 4px;
            border: 1px solid $lightGrey;

            transition: background .3s ease-in-out;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            background-color: $lightGrey;
          }

          /* When the checkbox is checked, add a blue background */
          .container input:checked ~ .checkmark {
            background-color: $colorAccent1;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .container .checkmark:after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }


        li.is-title{
          &:before{
            display: none;
          }
          position: relative;
          left: -25px;
          padding: 15px 0 5px 0;
          margin-bottom: 30px;
          border-bottom: 1px solid #000;
          h1, h2, h3, h4, h5, h6{
            text-transform: uppercase;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 30px;

      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }
      .recette-sub-header
      {
        background-color: $colorAccent4;
        h2
        {
          color: $white;
          margin: 0;
        }
      }
      ul
      {
        list-style-type: decimal;
        padding: 30px 30px 10px 60px;
        margin: 0;
        background: $lighterGrey;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 15px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
          a{
            color: $colorAccent3;
          }
        }
      }
      ul+h1,
      ul+h2,
      ul+h3,
      ul+h4,
      ul+h5,
      ul+h6{
        padding: 0 25px 0 0;
        line-height: 1.4;
        margin-bottom: 0;
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        border-bottom: 1px solid #000;
        text-transform: uppercase;
        a{
          color: $colorAccent3;
        }
      }
      ul+p b{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: 0;
        margin-left: 15px;
        a{
          color: $colorAccent3;
        }
      }
      .videoWrapper
      {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        margin-left: 10px;
      }
      .videoWrapper iframe
      {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .recette-sub-header
    {
      background-color: $colorAccent1;
      padding: 30px;
      color: #fff;

      h2
      {
        @extend .text-subtitle;
      }
    }

  }
}

.produit-wrap
{
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 1000;

  .produit-data
  {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;
    background-color: #fff;


    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    line-height: 30px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      font-family: $fontText;
      color: rgba(0, 0, 0, 0.7);
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;
      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .produit-photo
    {
      position: relative;
      width: 40%;
      border: 1px solid rgba(0,0,0,0.2);
      display: inline-block;
      float: left;
      padding: 40px;
      @media all and (max-width: 767px){
        width: 100%;
      }
      .canvas{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      img
      {
        width: 100%;
        margin: 0;
        transform: scale(1.1);
      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 5px;
      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }
      .description-data
      {
        padding: 25px;
        p
        {
          margin: 0;
          a{
            color: $colorAccent3;
          }
        }
        h2
        {
          margin-top: 25px;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 500;
          font-family: $fontTitle;
        }
        .show-overlay-form
        {
          margin-top: 25px;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 500;
          font-family: $fontTitle;
          color: $colorAccent2;
          display: block;
        }
      }
      .produit-sub-header
      {
        background-color: $colorAccent1;
        h2
        {
          color: #fff;
          margin: 0;
        }
        @media all and (max-width: 767px){
          background-color: $colorAccent1;
          h2
          {
            color: #fff;
          }
        }
      }
      ul
      {
        list-style-type: decimal;
        padding: 25px 25px 15px 35px;
        margin: 0;
        background: $lighterGrey;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 15px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .produit-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: #fff;
      h2
      {
        width: 100%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-family: $fontTitle;
      }
    }

  }
}

.blog-page {

  .list-wrap {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
