
.bloc-quoteCta{
  position: relative;
  padding: 10vw 50px;
  background: $light;
  text-align: center;

  .title2{
    font-size: 38px;
    padding-bottom: 40px;
    font-weight: 500;
  }

  .title3
  {
    font-weight: 500;
  }

  .title1,
  .title2,
  .title3,
  .main-text,
  .main-list{
    color: $colorAccent1;
  }

  @media all and (max-width: 1024px) {
    padding: 10vw 20px;

  }

  @media all and (max-width: 819px) {
    .title2{
      font-size: 25px;
    }
  }

  @media all and (max-width: 599px) {
    padding: 20vw 20px;
    .title2{
      font-size: 17px;
      padding-bottom: 25px;
    }
  }

}