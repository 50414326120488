/**
 * Import Vendor for components dependencies
 *
 */
@import '_themes/css/vendors/parsley';
@import '_themes/css/vendors/sweetalert';
@import '_themes/css/vendors/flickity';

/**
 * Import website Framwork base
 *
 *    Settings
 *    --------
 *      Colors pallet and Fonts
 *
 *    Mixins
 *    --------
 *      Usefull CSS functions to help develop
 *
 */
@import '_themes/css/settings';
@import '_themes/css/mixins';

/**
 * Reset
 *
 */
@import '_themes/css/base/global';
@import '_themes/css/base/normalize';

/**
 * Small Components
 *
 */
@import '_themes/css/grid';
@import '_themes/css/icon-fonts';
@import '_themes/css/btns';
@import '_themes/css/forms';
@import '_themes/css/typography';
@import '_themes/css/pagination';






/**
 * Import Component's core modules
 *
 */
@import '_components/main-nav-fiex-opaque/assets/css/main-nav-fiex-opaque';
@import '_components/hero-1/assets/css/hero-1';
@import '_components/hero-title/assets/css/hero-title';
@import '_components/bloc-services-1/assets/css/bloc-services-1';
@import '_components/bloc-services-2/assets/css/bloc-services-2';
@import '_components/bloc-informations-1/assets/css/bloc-informations-1';
@import '_components/bloc-arguments-1/assets/css/bloc-arguments-1';
@import '_components/bloc-publications/assets/css/bloc-publications';
@import '_components/bloc-publication/assets/css/bloc-publication';
@import '_components/bloc-temoignages-1/assets/css/bloc-temoignages-1';
@import '_components/bloc-about/assets/css/bloc-about';
@import '_components/bloc-faq/assets/css/bloc-faq';
@import '_components/bloc-pricing-1/assets/css/bloc-pricing-1';
@import '_components/bloc-cta-footer-1/assets/css/bloc-cta-footer-1';
@import '_components/bloc-map-contact-1/assets/css/bloc-map-contact-1';
@import '_components/bloc-map-contact-2/assets/css/bloc-map-contact-2';
@import '_components/bloc-infolettre-1/assets/css/bloc-infolettre-1';
@import '_components/footer-nav-1/assets/css/footer-nav-1';
@import '_components/custom-popup-1/assets/css/custom-popup-1';
@import '_components/mobile-menu/assets/css/mobile-menu';
@import '_components/overlay-form/assets/css/overlay-form';

@import '_components/appointment-page/assets/css/appointment-page';
@import '_components/terms-page/assets/css/terms-page';


/**
 * Admin
 *
 */
@import '_themes/css/admin/admin';
@import '_themes/css/admin/loader';
@import '_themes/css/admin/datepicker';

