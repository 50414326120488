
.bloc-pricing{
  padding: 6vw 50px;
  text-align: center;
  background: $colorAccent2;

  .triangles
  {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 12vw;
  }

  .title3,
  .main-text{
    color: #fff;
    &.more{
      padding-top: 35px;
    }
  }

  .subtitle{
    font-size: 19px;
  }

  .pricing-container{
    position: relative;
    text-align: center;
    padding-top: 75px;

    .pricing-pannel-wrapper{
      position: relative;
      display: inline-block;
      width: 35%;
      height: auto;
      margin: 0 3px;
      background: $white;
    }

    .pricing-pannel{
      padding: 0 0;

      header{
        padding: 45px 20px;
        background: $white;
      }

      .title4
      {
        color: $dark;
        margin-bottom: 0;
      }

      .date
      {
        color: $dark;
        font-family: $fontTitle;
        font-size: 40px;
        font-weight: 400;
        display: inline-block;
        line-height: 1;
        margin-bottom: 10px;
      }

      .main-text{
        color: $grey;
        line-height: 1.2;
        letter-spacing: 1px;

        strong
        {
          color: $colorAccent1;
          font-weight: 600;
        }

        &.mention{
          padding-top: 20px;

          &.margin-bottom{
            margin-bottom: 47px;
          }


        }
      }

      .price{
        display: inline-block;
        font-family: $fontTitle;
        text-align: center;
        color: $colorAccent2;
        font-size: 48px;
        margin-top: 0;
        margin-bottom: 7px;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 4px;
      }

      .price-details{
        display: inline-block;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        padding-top: 25px;

        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        color: $colorAccent1;
      }

      .price-container{
        position: relative;
        width: 100%;
        text-align: center;

        .price-bloc{
          position: relative;
          padding: 43px 22px 22px 22px;
          background: rgba(0, 0, 0, .2);
          font-size: 38px;
        }
      }

      .site-btn-center{
        padding: 25px 0 0 0;
      }

      article{
        padding: 0 0;
        background: $light;

        .main-list{
          margin: 0;
          padding: 0;

          li{
            margin: 0;
            padding: 20px;
            border-bottom: 1px solid $colorAccent2;

            &.ghost{
              border-color: rgba(0, 0, 0, .25);
            }
          }
        }

      }

    }

  }

  .singe{
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    max-width: 500px;
  }

  @media all and (max-width: 1249px) {
    .main-list
    {
      font-size: 13px;
    }

    .pricing-container
    {
      .pricing-pannel-wrapper
      {
        width: 40%;
      }
    }

  }

  @media all and (max-width: 1024px) {
    padding: 40px 20px;

    .pricing-container{

      .pricing-pannel-wrapper{


        .pricing-pannel{

          .price-container{

            .price-bloc{
              font-size: 23px;
              &:last-child{
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 900px) {

    .singe{
      display: none;
    }

    padding: 25px 15px;

    .pricing-container
    {
      .pricing-pannel-wrapper
      {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;

        article .main-list li.ghost{
          display: none;
        }
      }
    }

  }

}



