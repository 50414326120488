.hero {
  position: relative;
  z-index: 999;
  overflow: hidden;
  min-height:500px;
  background:$light;

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    &.mobile {
      display: none;
    }

  }

  .scroll {
    width: 100%;
    max-width: 25px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -15px;
  }

  .partenaires-logo {
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%,0);
    z-index: 100;

    width: 100%;
    max-width: 450px;
    padding: 0 15px;
  }

  .overlay-opacity
  {
    width: 100%;
    min-height: calc( 85vh - 85px );
    //background-color: $colorAccent1;
    background-color: #000000;
    opacity: 0.4;

    &.home-hero, .home-concours {
      background-color: #270C0B;
      opacity: 0.7;
    }
  }

  &.home-hero,
  &.home-concours {
    h1.title1 {
      margin-bottom: 20px;
    }

    p.hero-subtitle {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .inspiration-tagline
    {
      width: 100%;
      max-width: 400px;
      display: inline-block;
    }

    .title1 {
      @extend .title1;
      color: $light;
      margin-bottom: 40px;

      @media (max-width: 767px) {
        font-size: 50px;
      }
    }

    .hero-subtitle {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: $colorAccent5;
    }

    .concours-text {
      color: #fff;
      margin-bottom: 35px;
      padding: 0;
    }

    .site-btn
    {
      //color: $colorAccent1;
    }

    .site-hr {
      background: $white;
      margin-bottom: 15px;
    }

    .title2 {
      font-weight: 500;
      font-size: 18px;
      color: $colorAccent1;
    }

  }

  @media all and (max-width: 767px) {

    .content {

      .tagline
      {
        font-size: 22px;
      }

      .title2 {
        font-size: 16px;
      }

    }

    img {

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .site-hr {
        margin-bottom: 10px;
      }

      .title2 {
        font-size: 14px;
      }

    }

  }

  @media all and (max-width: 374px) {

    .title2 {
      font-size: 14px;
    }

  }

}

.hero.fullheight {
  min-height: calc( 85vh - 85px );
  background: url('../images/hero-concours.jpg') $light center center no-repeat;
  background-size: cover;
  background-position: center center;

  /*&.en {
    background: url('../images/home-hero-en.jpg') $light center center no-repeat;
    background-size: cover;
    background-position: center center;
  }*/

  &.has-image {
    background: url('/uploads/heros/hero-background-original.jpg') $light center center no-repeat;
    background-size: 100%;
  }

  @media all and (max-width: 1000px){
    background-size: cover;
    &.chine {
      background-size: cover;
    }
  }
}

.hero.hero-title {
  min-height: calc( 85vh - 85px );
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: 100%;
  background-position: center center;
  &.chine {
    background: url('../images/hero-concours.jpg') $light center center no-repeat;
    background-size: 100%;
  }

  &.about {
    background: url('../images/hero_home.jpg') $light center center no-repeat;
    background-size: 100%;
  }

  &.product-hero {
    background: url('../images/hero_home.jpg') $light center center no-repeat;
  }

  @media all and (max-width: 1000px){
    background-size: cover;
    &.chine {
      background-size: cover;
    }
  }
}
