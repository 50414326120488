
.bloc-arguments{
  padding: 6vw 50px;
  text-align: center;
  background: $light;

  i{
    color: $colorAccent2;
    font-size: 75px;
  }

  .title3{
    padding: 0;
    font-family: $fontTitle;
    font-weight: 500;
  }

  .title4{
    padding-top: 15px;
    color: $colorAccent1;
    font-weight: 500;
    font-size: 18px;
  }

  .main-text{
    color: $colorAccent1;
  }

  .arguments{
    padding: 6vw 50px;
  }

  .argument{
    text-align: center;
    padding: 0 3%;
  }

  @media all and (max-width: 1024px) {
    padding: 60px 20px;
    .arguments{
      padding: 40px 20px;
    }
    .argument{
      padding: 5%;
    }
  }

}