.site-btn-center{
  position: relative;
  width: 100%;
  text-align: center;
  @extend %clearfix;
  padding-top: 2vw;
  padding-bottom: 4vw;
}

.site-btn{
  display: inline-block;
  margin: 0;
  padding: 15px 40px;
  border-radius: 4px;
  background: $colorAccent1;
  text-align: center;
  color: $white;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  font-family: $fontTitle;
  transition: all 0.2s ease-in-out;

  &:hover{
    box-shadow: inset 0 80px 0 rgba(255, 255, 255, 0.2);
  }

  &:active {
    box-shadow: inset 0 80px 0 rgba(0, 0, 0, 0.2);
  }

  &.has-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }

    svg,
    path {
      fill: $white;
    }
  }

  &.is-outlined {
    border: 1px solid $colorAccent1Lighter;
    color: $colorAccent1;
    background: transparent;

    &:hover {
      border-color: $colorAccent1;
      background: rgba($colorAccent1, 0.2);
    }

    &.has-icon {
      svg,
      path {
        fill: $colorAccent1;
      }
    }
  }

  //&.grey{
  //  background: $grey;
  //  border-color: $grey;
  //
  //   &:hover{
  //    background: $white;
  //    border-color: $white;
  //    color: $colorAccent2;
  //  }
  //}
  //
  //&.white{
  //  background-color: $white;
  //  border-color: $white;
  //  color: $colorAccent2;
  //
  //  &:hover{
  //    background: transparent;
  //    color: $white;
  //  }
  //}
  //
  //&.outlined{
  //  background: transparent;
  //  border-color: $colorAccent2;
  //  color: #fff;
  //
  //  &:hover{
  //    background: $colorAccent2;
  //    border-color: $colorAccent2;
  //    color: $white;
  //  }
  //}
  //
  //&.contrast{
  //
  //  &:hover{
  //    border-color: $colorAccent2;
  //  }
  //}
  //
  //&.over-black
  //{
  //  &:hover
  //  {
  //    background-color: #000;
  //    color: #fff;
  //    border-color: #000;
  //  }
  //}
  //
  //@media all and (max-width: 767px) {
  //  padding: 13px 20px;
  //  font-size: 14px;
  //}
  //
  //@media all and (max-width: 599px) {
  //  padding: 13px 15px;
  //  font-size: 13px;
  //}

}
