
/*
 *    Colors
 *    ------
 */


$black : #000;
$white : #fff;

$darkGrey: #000000;
//$darkGrey: #515151;
$darkerGrey: #444444;
$grey : #8C8C8C;
$gray : #8C8C8C;
$lightGrey: #D8D8D8;
$lighterGrey: #F5F5F5;

$light : #f2f4f6;
$dark   : #002C50;
$darker : #002C50;

$colorAccent1 : #B4332F;
$colorAccent1Lighter : #FFD0CE;
$colorAccent2 : #F1E9D4;
$colorAccent3: #FFF8F2;
$colorAccent4: #281B14;
$colorAccent5: #FFD6A8;
$colorAccent6: #591715;


/*
 *    Fonts
 *    -----
 */

$fontTitle          : 'Lobster' ,sans-serif;
$fontMainTitle      : 'Lobster' ,sans-serif;
$fontTitleBold      : 'Lobster' ,sans-serif;
$fontText           : 'Open Sans' ,sans-serif;
$fontSystem         : -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

/*
 *    Breakpoints
 *    -----------
 */

