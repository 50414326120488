
.contact {
  position: relative;
  z-index: 1000;
  background-color: #fff;

  .coordo-wrap
  {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
    padding: 100px 2rem;
    //@extend %clearfix;

    @media all and (max-width: 899px) {
      flex-direction: column;

    }

    .coordo
    {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: calc(33.33% - (80px /  3));
      min-height: 215px;
      margin-right: 40px;
      padding: 35px;
      float: left;
      border: 1px solid #D8D8D8;
      border-radius: 4px;

      &:last-of-type {
        margin-right: 0;
      }

      @media all and (max-width: 899px){
        width: 100%;
        margin-bottom: 25px;
        margin-right: 0;
      }

      svg
      {
        display: inline-block;
        width: 100%;
        max-width: 24px;
        margin-bottom: 35px;
      }

      a
      {
        display: inline-block;
        color: $colorAccent1;
        text-decoration: underline;
      }
    }

    p
    {
      margin: 0;
    }
  }

  .map{
    position: relative;
    width: 100%;
    height: 40vw;
    min-width: 100%;
    max-width: 100%;
    max-height: 500px;
    border-bottom: 5px solid #fff;

    z-index: 1;

    @media all and (max-width: 560px){
      height: 80vw;
    }
  }

}
