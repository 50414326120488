.main-form{
  padding: 25px 0 0 0;

  .form-row{
    @extend %clearfix;
  }

  .form-field,
  .form-input{
    position: relative;
    width: 100%;

    &.half{
      float: left;
      width: 49%;
      margin-right: 2%;

    }

    &.one-third{
      float: left;
      width: 32%;
      margin-right: 2%;
    }

    &.one-forth{
      float: left;
      width: 24%;
      margin-right: 2%;

      @media only screen and (max-width: 365px) {
        width: 63%;
        margin-right: 2%;

        &:last-child{
          width: 35%;
          margin-right: 0;
        }

     }

    }

    &.three-forth{
      float: left;
      width: 74%;
      margin-right: 2%;

       @media only screen and (max-width: 365px) {
          width: 63%;
          margin-right: 2%;
       }

    }

    &:last-child,
    &.last
    {
      margin-right: 0;
    }

  }



  label{
    display: inline-block;
    width: 100%;
    text-align: left;
    color: $grey;
    font-weight: 400;
    font-size: 16px;

    span{
      display: block;
    }

  }

  .form-row{

    input,
    select,
    textarea{
      width: 100%;
      height: 50px;
      padding: 5px 10px;
      margin-bottom: 10px;
      border: 1px solid rgba(0, 0, 0, .10);

      background-color: #ABB0B7;
      border-radius: 3px;

      color: #3a3a3a;
      font-weight: 400;

      outline: none;

    }

    textarea{
      height: 175px;
      min-height: 175px;
      max-width: 100%;
      min-width: 100%;
    }

    input[type="submit"],
    button{

      outline: none;

    }

    input[type="submit"].site-btn,
    button.site-btn{


    }

  }


  .payment-errors{
    color: #e74c3c;
  }

  input.parsley-success,
  select.parsley-success,
  textarea.parsley-success{
    color: #3a3a3a;
  }

  input.parsley-error,
  select.parsley-error,
  textarea.parsley-error{
    color: #DA620C;
  }

  .checkbox{
    line-height: 25px;
    vertical-align: top;

    label span,
    input{
      display: inline-block;
      width: inherit;
      height: 25px;
      margin-right: 0.5%;
      line-height: 25px;
      vertical-align: top;
    }

  }


  .has-error{

    input,
    select{
      background: #e74c3c;
    }

  }

  .parsley-errors-list{
    position: absolute;
    right: 0;
    bottom: -7px;
    font-size: 12px;
    font-weight: 500;
    color: #DA620C;
  }

}

.main-form{

  .custom-select{
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 0;
    width: 100%;

    .select,
    .select2-container--default .select2-selection--single{
      cursor: pointer;
      outline: none;
      border: none;
      border-radius: 0;
    }

    .select2-container--default .select2-selection--single{
      background: #eee;
      width: 100%;
      height: 40px;
      min-height: 40px;
      padding: 5px 5px;
      border-radius: 0;
      margin: 0 0 0 0;
      font-size: 14px;
      letter-spacing: 1px;
      color: #565656;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow{
      top: 7px;
      right: 7px;
    }

    input{
      border-radius: 4px;
      font-size: 14px;
    }

    .options{
      position: absolute;
      top: 35px;
      left: 0;
      vertical-align: top;
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0 0;
      background: $white;
      border-radius: 3px;
      color: $grey;
      text-align: left;
      list-style-type: none;

      opacity: 0;
      z-index: -1;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;

        transform: translate( -50%, -50%);

        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        border-bottom: 15px solid $white;

      }

      transition: all 0.30s ease;

      &.visible{
        top: 100px;
        z-index: 1;
        opacity: 1;
      }

      .option{
        display: block;
        padding: 0 0;
        border-bottom: 2px solid #eee;
        color: $grey;
        text-align: left;
        font-size: 85%;
        background: rgba(0, 0, 0, 0);
        vertical-align: middle;

        transition: all 0.30s ease;

        &:hover{
          background: rgba(0, 0, 0, 0.05);
        }

        .thumb{
          display: inline-block;
          width: 100px;
          height: auto;
          vertical-align: middle;
          margin-right: 25px;
        }

        &:first-child{
          border-top-left-radius: 6px;
          overflow: hidden;
        }

        &:last-child{
          border-bottom-left-radius: 6px;
          overflow: hidden;
        }

      }

    }

    .form-select{
      display: none;
    }

    .form-select-value{
      position: absolute;
      top: -5555px;
      left: -5555px;
    }


    @media only screen and (max-width: 767px) {
      font-size: 13px;
    }

  }


}