.bloc-services2{

  background-color: #fff;
  position: relative;
  z-index: 1000;

  .row {
    display: flex;
    flex-wrap: wrap;
    //padding: 50px 0;

    @media(max-width: 1023px){
      flex-wrap: wrap-reverse;
    }
  }

  .content {
    width: 50%;
    height: auto;
    display: flex;

    max-width: 500px;
    margin: auto;
    padding: 100px 0;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 30px 0;
    }

    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      max-width: 85%;
      background-color: #fff;

      @media screen and (max-width: 1330px) {
        // padding: 6vw 50px;
      }

      .main-text  {
        @extend .main-text;
        color: $darkGrey;
      }

      .title3 {
        @extend .title4;
        color: $darkGrey;
        margin: 0 0 30px;
      }

      .site-btn {
        margin: 20px 0 0;
      }
    }

  }

  .visual {
    width: 50%;
    height: auto;
  }

  .services-carousel {

    .carousel-cell{
      width: 100%;
      height: 100%;
      background: url('../images/promo-fr.jpg') center bottom no-repeat;
      background-size: cover;

      &.en {
        background: url('../images/promo-en.jpg') center bottom no-repeat;
        background-size: cover;
      }
  }

  @media all and (max-width: 1499px) {
    .col.half.content{
      width: 50%;
    }
    .col.half.visual{
      width: 50%;
    }
  }

  @media all and (max-width: 1023px) {

    .col.half.content{
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      width: 100%;

      .wrap{
        padding: 40px 20px;
      }
    }

    .col.half.visual{
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      height: auto;
    }
  }

  }

  .col.half.promo-concours-half {

    .text-subtitle {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: $colorAccent1;
    }

    h3.title3 {
      margin-bottom: 23px;
    }

    ul {
      margin: 0;
      padding-left: 20px;

      p {
        margin: 0;
      }
    }

    p {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
    }

    .promo-btn-container {
      margin-top: 24px;
      display: flex;

      a:first-child {
        margin-right: 10px;
      }

      .site-btn {
        font-size: 18px;
      }
    }
  }
}
