/* --------------------------------

Table of content
  -#Imports
  -#Vars
  -#Vb-fullScreenLogin
  -#Parsleyjs - validation assets
  -#Responsive

-------------------------------- */


/* --------------------------------

#Vb-fullScreenLogin

-------------------------------- */
.vb-fullScreenLogin{

  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: url('../images/hero.jpg') transparent center center no-repeat;
  background-size: cover;
  background-attachment: scroll;

  .fsl-gradients{
    position: relative;
    width: 100%;
    height: 100%;

    z-index: 1;




    background: -webkit-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* Chrome 10+, Saf5.1+ */
    background:    -moz-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* FF3.6+ */
    background:     -ms-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* IE10 */
    background:      -o-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* Opera 11.10+ */
    background:         linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* W3C */




   opacity: 0.65;

  }

  .fsl-content{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    min-height: 555px;

    z-index: 2;


    backface-visibility: hidden;
    transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
    filter: blur(0);
  }

  .fsl-nav{
    position: relative;
    width: 100%;
    height: auto;
    padding: 25px 0 65px 25px;

    a{
      display: inline-block;
      margin: 0;
      padding: 5px 0;
      font-size: 25px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 35px;
     opacity: 0.5;

      &.active{
        opacity: 1;
        border-bottom: 2px solid #fff;
      }

    }

  }

  .fsl-form-signin{
    position: relative;
    width: 100%;
    height: auto;

    .form-signin-heading{
      display: none;
    }

    label{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      color: #fff;
      padding: 10px 25px;
      margin: 10px 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      opacity: 1;

    }

    input{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      background: rgba(255, 255, 255, .45);
      border: none;
      outline: none;
      border-radius: 25px;
      line-height: 1.5;
      padding: 10px 25px;
      margin: 10px 0;
      font-size: 16px;
      color: $darker;
      font-weight: 400;
    }

    .checkbox{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding-bottom: 3px;

      input{
        float: left;
        width: auto;
        height: auto;
        margin: 1px 15px 0 0;
        padding: 0;
      }

      label{
        float: left;
        text-transform: none;
        opacity: 1;
        font-size: 16px;
      }

    }

    .btn{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      background: $colorAccent1;
      border: none;
      outline: none;
      border-radius: 25px;
      line-height: 1.5;
      padding: 12px 25px;
      margin: 10px 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;

      transition: all .45s ease-out;

      &:hover{
        background: #fff;
        color: $darker;
      }

    }

    hr{
      position: relative;
      display: block;
      width: 100%;
      height: 3px;
      border: none;
      background: #fff;
      opacity: 0.45;
      border-radius: 25px;
      margin: 75px 0 0 0;
      padding: 0 0;
    }

    .fsl-foot-links{
      position: relative;
      width: 100%;
      height: auto;
      padding: 25px 25px;
      text-align: center;

      a{
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        opacity: 1;
        padding: 15px;

        transition: all .45s ease-out;

        &:hover{
          opacity: 1;
        }

      }

    }

  }


  /* --------------------------------

  #Parsleyjs

  -------------------------------- */
  .fsl-form-signin{
    .parsley-errors-list{
      margin: 0;
      padding: 0 25px;
      list-style-type: none;

      li{
        margin: 0;
        padding: 0 10px;
        border-left: 1px solid #fff;
        color: $darker;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }








  /* --------------------------------

  #Responsive

  -------------------------------- */

  @media only screen and (max-width: 375px) {
    position: relative;
    width: auto;
    height: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    .fsl-gradients{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
    }

    .fsl-content{
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      padding: 0 20px;
    }

    .fsl-nav{
      padding-bottom: 35px;

      a{
        margin-right: 15px;
      }

    }
    .fsl-form-signin hr{
      margin-top: 35px;
    }
  }


  @media only screen and (max-width: 320px) {
    .fsl-nav{
      padding-bottom: 0;
    }
    .fsl-form-signin hr{
      margin-top: 0;
    }
    .fsl-form-signin label{
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .fsl-form-signin input{
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .fsl-form-signin .fsl-foot-links{
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }




}