.block-about {
  position: relative;
  z-index: 1000;
  background: $white;

  .about-container {
    position: relative;
    width: 100%;
    max-width: 1190px;
    padding: 50px 2rem;
    margin: 0 auto;
    text-align: center;
  }

  .about-content {
    text-align: left;

    *:not(img) {
      width: 100%;
      max-width: 730px;
      margin: 0 auto;
    }

    img {
      display: block;
      width: 100%;
      margin: 0 auto 50px;
    }

    h1,
    h2 {
      margin-bottom: 20px;
      color: $darkGrey;
    }

    h1 {
      @extend .title4;
      margin-top: 50px;
    }

    h2 {
      @extend .title5;
    }

    p {
      margin: 0 auto 25px;
      color: $darkGrey;
    }

    a {
      color: $colorAccent1;
      text-decoration: underline;
      transition: .4s ease;

      &:hover {
        color: darken($colorAccent1, 5);
      }
    }
  }

  .promo-content {
    p {
      margin: 0 auto;
    }

    ul {
      margin-top: 10px;
      padding-left: 20px;
    }
  }

  .promo-btn-container {
    margin-top: 70px;

    a:first-child {
      margin-right: 6px;
    }
  }

  .promo-visual {
    max-width: 100%;
    margin-top: 85px;
  }

  .about-img {
    max-width: 50%;
    margin-top: 50px;
  }

  .concours-content {
    text-align: center;

    h1 {
      margin-bottom: 50px;
    }

    p {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;

      text-align: center;
      margin: 0 auto;
      color: #515151;
    }
  }

  .mailchimp-form {
    padding: 100px 40px;
    background: $colorAccent2;

    h2 {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 50px;
      /* identical to box height */

      text-align: center;
      color: $darkGrey;
    }

    form {
      margin: auto;
      max-width: 475px;

      input:not([type=radio],[type=checkbox],[type=submit]) {
        border: 0;
        width: 100%;
        background: $white;
        padding: 18px 28px;
        border-radius: 4px;
        margin-bottom: 24px;
      }

      .input-group {
        ul {
          margin-top: 24px;
          list-style: none;
          padding-left: 0;

          li {
            margin-bottom: 24px;
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 180%;

            label {
              margin-left: 12px;
            }
          }
        }
      }

      .separate-group {
        border-top: 1px solid $lightGrey;
      }

      #mce-error-response,
      #mce-success-response,
      #mce-error-checkbox-response {
        border-left: 3px solid;
        border-radius: 4px 0 0 4px;
        padding: 5px 0 5px 10px;
      }

      #mce-error-response,
      #mce-error-checkbox-response {
        border-left-color: $colorAccent1;
        background: rgba($colorAccent1 , .3);
      }

      #mce-success-response {
        border-left-color: #008000;
        background: rgba(47,180,76, .3);
      }

      .submit-contest:disabled {
        box-shadow:inset 0 80px 0 rgba(255, 255, 255, 0.2);
      }

      .btn-holder {
        text-align: center;
      }

      .site-btn {
        border: 0;
        margin-top: 50px;
      }

      .link-text {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}
