.overlay{

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 28, 36, .75);

  opacity: 0;

  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.visible{
    opacity: .8;
    z-index: 100;
  }

}

.custom-popup{

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -70%);

  width: 100%;
  max-width: 500px;
  height: auto;
  padding: 0 0;

  background: $light;
  text-align: center;

  opacity: 0;
  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.visible{
    opacity: 1;
    z-index: 200;
    transform: translate(-50%, -50%);
  }

  .title3{
    position: relative;
    width: 100%;
    line-height: 1;
    padding: 25px 0;
    text-align: center;
    background: $white;
    color: $grey;
    font-size: 24px;

    strong{
      color: $colorAccent1;
    }

    &:after{
      content: '';

      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 95%);

      width: 0;
      height: 0;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 12px solid $white;
    }

  }

  .content{
    padding: 35px 50px;
  }

  hr{
    position: relative;
    width: 65px;
    height: 2px;
    margin: 2% auto 2% auto;
    padding: 0;
    background: $colorAccent1;
    border: none;
  }

  .cta-container .cta{
    font-size: 18px;
    margin-bottom: 0;
    padding: 15px 50px;
    border: none;
    box-shadow: 0 0 0 3px $colorAccent1;

    &:disabled{
      opacity: .65;
    }

  }

  .number{

    display: inline-block;

    font-size: 75px;
    color: $colorAccent1;
    font-weight: 400;
    text-align: center;

    border: 1px solid #899999;
    border-radius: 50%;

    width: 135px;
    height: 135px;

    line-height: 135px;

    }

  .text{
    padding: 15px 0;
    text-align: center;

    p{
      margin: 0;
      padding: 0;

      font-size: 17px;
      color: #899999;
      font-weight: 500;
      line-height: 1.4;

      text-align: center;
    }
  }

  .main-form .form-field.half{
    float: none;
    width: 100%;
  }

  .main-form input[type="submit"].site-btn,
  .main-form button.site-btn{
    margin-top: 25px;
  }

  .popup-close-btn{
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(50%, -50%);

    width: 40px;
    height: 40px;

    background: #fff;
    border: 1px solid $grey;
    border-radius: 50%;

    .close-cross{
      font-size: 32px;
      font-weight: 100;
      font-family: sans-serif;
      text-align: center;
      line-height: 33px;
      text-indent: 1px;
      color: $grey;
    }

  }

  @media only screen and (max-height: 899px) {

    .content {
      padding: 20px 45px;
    }

    .title3{
      padding-top: 15px;
      padding-bottom: 15px;
    }

    h2{
      font-size: 15px;
    }

    .main-form label{
      font-size: 12px;
    }

    .main-form input,
    .main-form select{
      height: 40px;
      margin-bottom: 0;
    }

    .main-form{
      padding: 0;
    }

    .main-form input[type="submit"].site-btn,
    .main-form button.site-btn{
      margin-top: 10px;
      line-height: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .main-form .parsley-errors-list {
      right: 3px;
      bottom: 1px;
      font-size: 10px;
    }

    .custom-popup .title3{
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 574px) {

    .popup-close-btn{
      top: 15px;
      right: 15px;
      transform: translate(0, 0);
    }

    .title3{
      text-align: left;
      padding-left: 15px;
    }

  }

  @media only screen and (max-width: 464px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 0;

    transform: none;

    overflow: scroll;

    &.visible{
      transform: none;
    }

    h2{
      font-size: 15px;
    }

    .main-form label{
      font-size: 12px;
    }

    .main-form input,
    .main-form select{
      height: 40px;
      margin-bottom: 0;
    }

    .main-form{
      padding: 0;
    }

    .main-form input[type="submit"].site-btn,
    .main-form button.site-btn{
      width: 100%;
      max-width: inherit;
      margin-top: 10px;
      line-height: 0;
    }

    .main-form .parsley-errors-list {
      right: 3px;
      bottom: 1px;
      font-size: 10px;
    }

    .custom-popup .title3{
      font-size: 19px;
    }

  }

  @media (max-height: 899px) and (max-width: 574px) {
    .popup-close-btn{
      top: 7px;
    }
  }

}
