.admin{
  background: #747881;

  .site-content{
    position: relative;
    min-height: 100vh;
    padding: 5% 10%;

    &.with-bg{
    background: url('../images/hero.jpg') transparent center center no-repeat;
    background-size: cover;
    background-attachment: scroll;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: -webkit-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* Chrome 10+, Saf5.1+ */
        background:    -moz-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* FF3.6+ */
        background:     -ms-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* IE10 */
        background:      -o-linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* Opera 11.10+ */
        background:         linear-gradient(145deg, $colorAccent1 10%, $darker 90%); /* W3C */

        opacity: .75;

        z-index: 1;

      }

      *{
        position: relative;
        z-index: 5;
      }

    }
  }

  .title2{
    text-align: center;
  }

  .site-btn{
    display: block;
    margin: 0 auto;
  }

  .success{
    color: #16a085;
  }

  .danger{
    color: #e74c3c;
  }

  .header {
    position: relative;
    width: 100%;
    height: 95px;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 5px;
    background: #21263a;

    .hoff-logo{
      display: block;
      float: left;
    }

    .actions{
      position: absolute;
      top: 50%;
      right: 65px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 17px;
      transform: translate(0, -50%);
      line-height: 1;
      margin: 0;
      padding: 0;

      i{
        position: relative;
        top: 2px;
        font-size: 130%;
        margin-left: 5px;
      }
    }

    .pop-menu{
      position: absolute;
      top: -50%;
      right: 65px;
      width: 215px;
      height: auto;
      background: #fff;
      border: 1px solid #eee;

      border-radius: 6px;

      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, .25);

      opacity: 0;

      transition: all 0.45s ease;

      z-index: -1;

      &.open{
        top: 70%;
        opacity: 1;
        z-index: 99;
      }

      a{
        display: block;
        padding: 5% 0;
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        letter-spacing: 1px;

        &:hover{
          background: #eee;
        }

      }

    }

    .gradient{
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: 100%;
      background: url('../images/admin-gradient.jpg') transparent 0 center no-repeat;
      background-size: 100% auto;
    }

  }

  .main-nav {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 25px;
    background-color: white;
    border-bottom: 0;

    z-index: 1;

    .links{
      float: left;
      a{
        float: left;
        position: relative;
        width: auto;
        height: 55px;
        padding: 0 25px;
        color: #333;
        line-height: 55px;
        text-transform: uppercase;
        font-size: 14px;
        &.active{
          &:after{
            content: '';
            position: absolute;
            left: 15%;
            right: 15%;
            bottom: 0;
            height: 3px;
            background: $colorAccent1;
          }
        }
      }
    }

    .add{
      position: relative;
      transform: translate( 0, 25%);
      float: right;
      margin-left: 15px;

      &.success{
        background: #16a085;
      }

      &.danger{
        background: #e74c3c;
      }
    }

  }

  .button{
    display: inline-block;
    padding: 5px 20px;
    background: $colorAccent1;
    color: #fff;
    border-radius: 4px;
  }

  .content-heading {
    padding: 10px 40px;
    background-color: #eee;
  }

  .table{
    width: 100%;
    margin: 0 auto;
    border: 0;
    border-collapse: collapse;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;

    tr{
      background: transparent;
      &.pending{
        background: #FFE5D6;
        border-bottom: 3px solid #EFD3C2;
      }
      &.annule{
        display: none;
      }
    }

    th{
      padding: 15px 35px;
      color: #fff;
      font-family: 'Open Sans';
      font-size: .9em;
      font-weight: 600;
      line-height: 2em;
      text-decoration: none;
      text-align: center;
      border: none;
      background: #21263a;
    }

    td{
      padding: 15px 35px;
      color: #333;
      font-size: .8em;
      font-weight: 400;
      line-height: 2em;
      text-decoration: none;
      text-align: center;

      &:first-child,
      &:last-child{
        background: #fbfbfb;
      }

      &.active{
        background: #F9F9F9;
      }

    }

    .action{
      display: inline-block;
      width: 26px;
      height: 26px;
      padding: 0 0;
      border-radius: 50%;
      background-color: $colorAccent1;
      border: 2px solid $colorAccent1;
      color: #e7eaf3;
      font-size: .8em;
      font-weight: 300;
      text-decoration: none;
      text-align: center;
      line-height: 23px;

      &.success {
        background: #16a085;
        border-color: #16a085;
      }

      &.danger {
        background: #e74c3c;
        border-color: #e74c3c;
      }

    }

  }

}

.admin.show{

  .bloc-visual{
    position: relative;
    top: 0;
    right: 0;
    width: 35%;
    height: auto;
    z-index: 5;
    padding: 50px 50px 50px 50px;
    float: left;

    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .bloc-infos{
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1400px;
    height: auto;
    margin: 0 auto;
    z-index: 5;
    padding: 50px 50px 50px 50px;
    background: #fff;
    border-radius: 0;

    &:first-child{
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child{
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.global-content{
      padding-top: 0;
      .form-row{
        border: none;
        max-width: 500px;
        margin: 0 auto;
      }
    }

    &.fr-content{
      background: #f9f9f9;
      border-top: 2px solid rgba(0, 0, 0, .03);
      border-bottom: 2px solid rgba(0, 0, 0, .03);
    }

    &.en-content{
      background: #fff;
    }

    .colLeft,
    .colRight{
      float: left;
      width: 50%;
    }

    .row{
      position: relative;
      width: 100%;
      padding: 0 35px 0 0;
      padding-bottom: 35px;
    }

    h1{
      padding-bottom: 15px;
      border-bottom: 2px solid #262626;
    }

    h2 {
      padding: 0 0 5px 0;
      // border-bottom: 1px solid #555;
      font-size: 22px;
    }

    p{
      margin: 0;
      padding: 0;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.5;
    }

    .notifications{
      text-align: center;
      .danger{
        display: inline-block;
        color: #e74c3c;
        text-align: center;
        border-top: 1px solid #e74c3c;
        border-bottom: 1px solid #e74c3c;
        margin: 10px;
      }
    }



  }

  .tagZone{
      position: relative;

      min-height: 100px;

      padding: 15px;
      border: 2px solid #eee;
      border-radius: 6px;

      @extend %clearfix;

      cursor: pointer;

      overflow: hidden;

      .plus{
        display: block;
        width: 100%;
        font-size: 45px;
        text-align: center;
        padding: 65px;
        color: #999;
      }

      .link{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        color: #fff;
        padding: 9px;
        font-size: 14px;
      }

      &::before,
      &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          border-color: transparent;
          border-style: solid;
      }

      &::before {
          border-width: 2em;
          border-right-color: #eee;
          border-bottom-color: #eee;
      }

      &::after {
          border-radius: 0.4em;
          border-width: 1.85em;
          border-right-color: #16a085;
          border-bottom-color: #16a085;
      }

  }

  .tag{
    display: inline-block;
    margin: 5px;
    padding: 0;
    background: #eee;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    img{
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    p{
      display: none;
      padding: 15px;
      margin: 0;
    }
    input[type="checkbox"]{
      display: none;
    }
    .fa{
      width: auto;
      padding: 29px 6px;
      float: left;
      font-size: 15px;
      color: #fff;
      &.delete{
        display: none;
        background: #e74c3c;
      }
      &.add{
        display: none;
        background: #16a085;
      }
    }
  }

  .link-tag-ref{
    .tag{
      display: none;
    }
  }

  .modal{
    .modal-body{
      text-align: center;
      .tag{
        .fa{
          &.delete{
            display: none;
          }
          &.add{
            display: block;
          }
        }
      }
    }
  }

}