.appointment{

  .main-nav{
    .site-btn{
      display: none;
    }
  }

  .back-btn{
    position: absolute;
    top: 125px;
    left: 50px;
    z-index: 5;
  }

  .bloc-appointment{
    height: auto;
    background: #f0f0f0;
    padding-bottom: 50px;
    
    .hero-banner
    {
      width: 100%;
      height: 70vh;
      background-color: #000;
      text-align: center;
      background-image: url('../images/hero.jpg');
      background-attachment: scroll;
      background-size: cover;
      background-position: center center;
      .title1
      {
        color: $dark;
        padding-top: 20vh;
        font-size: 36px;
        max-width: 610px;
        margin: 0 auto;
        font-weight: 500;
      }
    }

  }

  .main-form{

    padding: 0;

    .form-field.half,
    .form-input.half{
      width: 100%;
    }

  }

  .form-container{
    position: relative;
    top: 0;
    width: 100%;
    max-width: 535px;
    height: auto;
    padding: 45px;
    margin: 0 auto;
    background: $white;
    margin-top: -250px;

    .site-btn{
      margin-top: 25px;
      width: 100%;
    }

  }


  @media all and (max-width: 819px) {

    .bloc-appointment{
      height: auto;
      padding-top: 40px;
      padding-bottom: 40px;
      .hero-banner
      {
        background-attachment: scroll;
        background-size: cover;
      }
    }

  }

  @media only screen and (max-width: 650px)
  {
    .form-container
    {
      margin-top: -50px;
      padding: 25px;
    }
    .bloc-appointment{
      padding-bottom: 0;
      .hero-banner
      {
        height: 35vh;
        padding: 0 25px;
        .title1
        {
          font-size: 26px;
          padding-top: 10vh;
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {



    .bloc-appointment{

      .form-container{
        margin-top: 0;
        padding: 25px;
      }

      .form-container{
        padding-left: 25px;
        padding-right: 25px;
      }


    }

  }

}