// Typography

.title1 {
  margin: 0;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 110%;
  color: $black;
}

.title2 {
  margin: 0;
  font-family: $fontTitle;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 90px;
}

.title3 {
  margin: 0;
  font-family: $fontTitle;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 80px;
}

.title4 {
  margin: 0;
  font-family: $fontTitle;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
}

.title5 {
  margin: 0;
  font-family: $fontTitle;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.title1, .title2, .title3, .title4{

  i{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin-top: -15px;
    font-size: 255%;
    color: $grey;
  }

  //strong{
  //  font-family: $fontTitleBold;
  //}

  @media all and (max-width: 969px) {
    i{
      margin-top: 0;
    }
  }

}

.main-text {
  margin: 0;
  color: $black;
  font-family: $fontText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
}

.text-subtitle {
  margin: 0;
  color: $black;
  font-family: $fontSystem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.text-caption {
  margin: 0;
  color: $black;
  font-family: $fontSystem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.main-list{
  width: 100%;
  color: $dark;
  margin: 0;
  padding: 25px 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;

  list-style-type: none;

  li{
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    padding-left: 30px;
    line-height: 1;

    i{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      color: $colorAccent2;
      font-size: 135%
    }

  }

}

a {
  cursor: pointer;
}

.site-hr-container{
  @extend %clearfix;
}

.site-hr{
  display: inline-block;
  width: 55px;
  height: 5px;
  background: $grey;
  margin: 0 auto 25px auto;
  padding: 0;
  border: none;
}
