
.bloc-temoignages{
  padding: 6vw 50px;
  text-align: center;
  background: $white;

  .title3{
    color: $colorAccent1;
    padding-bottom: 50px;
    font-weight: 500;
  }

  .ico-temoignages {
    display: inline-block;
    padding: 5px 0 20px 0;
    font-size: 60px;
    color: $light;
  }

  .main-text{
    width: 100%;
    max-width: 800px;
    height: auto;

    padding: 0;
    margin: 0 auto 50px auto;
    text-align: center;
    color: $colorAccent1;

    strong{
      color: $colorAccent1;
    }
  }

  span{
    display: block;
    color: $colorAccent1;
    line-height: 1.3;

    &.second{
      color: $grey;
    }
  }

  .temoignages-carousel{
    padding-bottom: 100px;

    .carousel-cell{
      position: relative;
      width: 100%;
      height: auto;
    }

    .flickity-page-dots{

      bottom: 0;

      .dot{

        width: 13px;
        height: 13px;
        margin: 0 5px;

        background: transparent;
        border: 2px solid $colorAccent1;
        opacity: 1;

        &.is-selected{
          background-color: $colorAccent1;
          border-color: $colorAccent1;
        }
      }
    }

  }

  @media only screen and (max-width: 1024px) {
    padding: 60px 20px;
  }

  @media only screen and (max-width: 929px) {

    .temoignages{
      h2{
        font-size: 28px;
      }
      p{
        font-size: 18px;
        line-height: 1.2;
      }
      img{
        max-width: 50px;
      }
    }

  }

  @media only screen and (max-width: 739px) {

    .temoignages{
      h2{
        font-size: 20px;
      }

    }

  }

}



.bloc-temoignages.showfaces{

  .temoignages-carousel{
    padding-bottom: 170px;

    .flickity-page-dots{

      .dot{
        position: relative;
        width: 95px;
        height: 95px;
        overflow: hidden;

        margin: 0 25px;

        border: none;
        opacity: .45;

        background: url('../images/user-placeholder.jpg') transparent center center no-repeat;
        background-size: cover;

        &.is-selected{
          opacity: 1;
        }

      }

    }

  }


  @media only screen and (max-width: 739px) {

    .temoignages-carousel{
      padding-bottom: 75px;

      .flickity-page-dots{

        .dot{
          width: 45px;
          height: 45px;

          margin: 0 15px;

        }

      }

    }

  }

}



