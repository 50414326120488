.faq .hero-title {
  background-image: url('../images/faq-hero.jpg');
  background-size: cover;
}

.block-faq {
  position: relative;
  z-index: 1000;
  background: $colorAccent3;

  .faq-container {
    position: relative;
    width: 100%;
    max-width: 1190px;
    padding: 50px 2rem;
    margin: 0 auto;
    text-align: center;

    h1,
    h2 {
      margin-bottom: 20px;
      color: $darkGrey;
    }

    h1 {
      @extend .title4;
    }

    h2 {
      @extend .title5;
    }
  }

  .faq-row {
    text-align: left;

    &.is-active {
      .faq-title {
        color: $white;
        background: $colorAccent1;
        border: none;

        &:hover {
          background-color: rgba($colorAccent1, .9);
        }

        span {
          background-image: url('../images/arrow.png');
          transform: rotate(90deg);
        }
      }

      .faq-content {
        display: block;
      }
    }

    .faq-title {
      position: relative;
      width: 100%;
      padding: 20px 30px;
      font-weight: bold;
      background: $colorAccent2;
      color: $darkGrey;
      border-bottom: 1px solid $colorAccent3;
      transition: all .3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: rgba($colorAccent2, .5);
      }

      span {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 30px;
        width: 8px;
        height: 12px;
        background-image: url('../images/arrow-black.png');
        background-repeat: no-repeat;
      }
    }

    .faq-content {
      background: $white;
      padding: 30px;
      display: none;

      .link {
        text-decoration: underline;
      }

      p, ul {
        margin-top: 0;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom:0;
        }
      }
    }
  }
}
