.recettes
{
  .hero-title
  {
    background-image: url('../images/hero-recettes.jpg');
  }
}

.produits
{
  .hero-title
  {
    background-image: url('../images/hero-produits.jpg');
  }
}

.contact
{
  .hero-title
  {
    background-image: url('../images/hero_home.jpg');
  }
}

.publications
{
  .hero-title{
    background-image: url('../images/blogue-hero.jpg');
  }
}

.about
{
  .hero-title{
    background-image: url('../images/hero_home.jpg');
  }
}

.promotion.hero-title{
  background-image: url('../images/hero_home.jpg');
}

.concours.hero-title {


  .hero-subtitle {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: $colorAccent5;
  }

  .overlay-opacity {
    background-color: #270C0B;
    opacity: 0.7;
  }
}

.hero-title {
  position: relative;
  z-index: 1000;
  overflow: hidden;
  min-height: 400px;
  background-size: 100%;
  background-position: center center;

  @media all and (max-width: 1024px){
    min-height: 250px;
  }
  @media all and (max-width: 767px){
    padding: 100px 0;
    min-height: 0;
  }

  @media all and (max-width: 1000px){
    background-size: cover;
  }

  .recette-timer {
    margin-top: 20px;
    font-weight: bold;
    color: $colorAccent5;
  }

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    &.mobile {
      display: none;
    }

  }

  .overlay-opacity
  {
    width: 100%;
    min-height: 400px;
    //background-color: #270C0B;
    background-color: #000000;
    opacity: 0.4;

    @media all and (max-width: 1024px){
      min-height: 250px;
    }

    @media all and (max-width: 767px){
      height: 100%;
      min-height: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    @media (max-width: 767px) {
      position: relative;
      transform: none;
      top: 0;
    }

    .text-subtitle {
      color: $white;
    }

    .title4 {
      margin: 0 auto;
      color: $white;
      padding: 0;

      span {
        color: $colorAccent1;
      }

    }

    .site-btn {
      margin-top: 20px;
    }

  }

  @media all and (max-width: 767px) {

    .content {

      .title1 {
        font-size: 24px;
        line-height: 1.5;
        padding: 0;
        margin-bottom: 20px;
        max-width: 540px;
      }

    }

    img {

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .title1 {
        font-size: 22px;
        line-height: 1.2;
      }

    }

  }

}
