
.contact2{
  position: relative;

  .map{
    position: relative;
    width: 100%;
    height: 30vw;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    z-index: 1;
  }

  .adresses{
    position: relative;
    width: 100%;
    height: auto;
    background: $white;

    @extend %clearfix;

    .bloc-adresse{
      position: relative;
      float: left;
      width: 50%;
      height: auto;
      padding: 100px 40px;
      text-align: center;

      cursor: pointer;

      &:hover{
        background: $light;
      }

      &.active{
        background: $light;
      }

      &:first-child{
        border-right: 1px solid #eee;
      }

      &:last-child{
        border-left: 1px solid #eee;
      }

      .title4{
        text-align: center;
        font-size: 22px;
        margin: 0;
        padding: 0 0 35px 0;
      }

      p,
      a{
        padding: 0 0 40px 0;
        margin: 0;

        color: $black;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
      }

      p{
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      a{
        transition: all 0.35s ease;

        &:hover{
          color: $colorAccent1;
        }

      }

      i{
        font-size: 150%;
        color: $colorAccent2;
      }

    }

  }

  .foot-note{
    position: relative;
    background: $colorAccent1;
    z-index: 3;

    border-top: 5px solid $colorAccent2;

    @extend %clearfix;

    p{
      float: left;
      width: 50%;
      padding: 25px 25px;
      margin: 0;
      color: $white;
      text-align: left;
      line-height: 1;

      &.links{
        text-align: right;
      }

    }

  }

  @media all and (max-width: 979px) {
    border-top: 5px solid $white;
  }

 @media only screen and (max-width: 1278px) {
  .adresses .bloc-adresse{
    padding: 41px 40px;

    p,
    a{
      font-size: 14px;
      line-height: 26px;
    }

  }
 }


  @media only screen and (max-width: 1139px) {
    .bloc-text{
      left: 10%;
    }
    .map{
      height: 375px;
    }
    .adresses{
      .bloc-adresse{

        p,
        a{
          font-size: 14px;
        }

      }
    }
  }

  @media only screen and (max-width: 919px) {
    .bloc-text{
      left: 7%;
    }
  }

  @media only screen and (max-width: 799px) {
    .bloc-text,
    .bloc-text .content{
      position: relative;
      float: none;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      transform: none;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;

      text-align: center;

      p, .title4{
        text-align: center;
      }

      p:last-child{
        margin-bottom: 0;
        padding-bottom: 0;

        text-align: center;
      }


    }
  }

  @media only screen and (max-width: 799px) {
    .foot-note{
      p, p.links{
        width: 100%;
        float: none;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 5px;

      }
      p.links{
        padding-top: 0;
        padding-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .adresses{
      .bloc-adresse{
        float: none;
        width: 100%;
        border: none;

        &.active{
          background: $light;

        }

        &:hover,
        &:first-child,
        &:last-child{
          border: none;

        }

        p,
        a{
          font-size: 12px;
        }

      }
    }
  }

  @media only screen and (max-width: 399px) {
    .foot-note{
      p, p.links{
        font-size: 13px;

      }
    }
  }

}

.contact.bottom{
  .bloc-text{
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    width: 40%;
    background: #fff;
    z-index: 2;
    transform: translate(-50%, 0);

    .content{
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: auto;
      padding: 75px 25px;

      .title4{
        text-align: center;
      }

      p,
      a{
        text-align: center;
      }

    }
  }
}