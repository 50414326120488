.header-title {
  position: relative;
  min-height: 70vh;
  background-color: $light;


  .content {
    color: #fff;
    padding-top: 30vh;
    max-width: 1000px;
    margin: 0 auto;

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .title1 {
      font-size: 32px;
      margin: 0 auto;
      max-width: 740px;
      color: $colorAccent1;
     
      span {
        color: $colorAccent1;
      }

    }

  }

  @media all and (max-width: 1024px) {
    padding-top: 80px;
  }

  @media all and (max-width: 767px) {
    padding-top: 65px;

    .content {
      
      .title1 {
        font-size: 24px;
        line-height: 1.5;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .title1 {
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 10px; 
      }

    }

  }

  @media all and (max-width: 469px) {
    padding-top: 61px;
  }

}

.header-title.fullheight {
  min-height: 100vh;
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: cover;
}

.policy-section {
  background-color: $colorAccent3;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    padding: 0;
  }

  .content {
    position: relative;
    top: 0;
    width: 100%;
    max-width: 850px;
    height: auto;
    padding: 45px;
    margin: 0 auto;
    background: #fff;
    margin-top: -150px;

    @media (max-width: 767px) {
      margin-top: 0;
    }

    .title3 {
      font-size: 16px;
      font-weight: 900;
      margin-top: 40px;
      padding-bottom: 19px;
      margin-bottom: 19px;
      position: relative;

      &:before {
        content: '';
        width: 75px;
        height: 2px; 
        background-color: $light;
        position: absolute;
        left: 0;
        bottom: 0;
      }

    }

    p.main-text {
      margin-bottom: 15px;
      line-height: 18px;
      font-size: 15px;
      color: $dark;

      strong {
        display: block;
        margin-bottom: 5px;
      }

    }

    ul {
      padding: 0;

      li {
        line-height: 18px;
        font-size: 15px;
        color: $dark;
        position: relative;

        &:before {
          content: "\e905";
          font-family: icomoon!important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          top: 0;
          left: 0;
          color: $colorAccent3;
          width: 20px;
          height: 20px;
          font-size: 20px;
        }

      }

    }

  }

}





