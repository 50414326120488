.publication-wrap
{
  position: relative;
  z-index: 100;
  width: 100%;

  .capsule-header
  {
    width: 100%;
    min-height: calc( 85vh - 85px );

    background: url('../images/hero.jpg') $light center top no-repeat;
    background-size: cover;

    text-align: center;

    position: relative;

    @media all and (max-width: 767px){
      min-height: 0;
    }

    .video-wrap
    {
      width: 100%;
      max-width: 850px;
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media all and (max-width: 767px){
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }

      .video
      {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe
        {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc(85vh - 85px);
      background-color: $colorAccent1;
      opacity: 0.9;
      @media all and (max-width: 767px){
        min-height: 0;
      }
    }
  }

  .publication-header-wrapper {
    .publication-header
    {
      position: relative;
      width: 100%;
      min-height: calc(85vh - 85px);

      background: $light center top no-repeat;
      background-size: cover;

      text-align: center;

      [data-bg-cover-img]{
        display: none;
      }

      .overlay-opacity
      {
        width: 100%;
        min-height: calc( 85vh - 85px );
        //background-color: $colorAccent1;
        background-color: #000000;
        opacity: 0.4;
        max-height: 600px;
      }

      .auteur
      {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;

        img
        {
          width: 80px;
          border-radius: 50px;
          margin-bottom: 10px;
        }

        .name
        {
          color: #fff;
          padding: 0;
          margin: 0;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 0.1em;
          line-height: 1;
          font-family: $fontTitle;
        }

        .date {
          @extend .text-subtitle;
          color: $light;
        }
      }

      .content {
        position: absolute;
        top: 45%;
        left: 0;
        transform: translate(0, -50%);

        width: 100%;
        height: auto;

        padding-left: 20px;
        padding-right: 20px;

        z-index: 2;

        @media all and (max-width: 767px){
          top: 40%;
        }

        text-align: center;

        .title1
        {
          @extend .title4;
          margin: auto;
          color: $white;

          span
          {
            color: $colorAccent1;
          }

        }

      }

    }
  }

  .publication-header
  {
    width: 100%;
    min-height: calc( 85vh - 85px );
    max-height: 600px;

    background: $light center top no-repeat;
    background-size: cover;

    text-align: center;

    [data-bg-cover-img]{
      display: none;
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc( 85vh - 85px );
      //background-color: $colorAccent1;
      background-color: #000000;
      opacity: 0.4;
    }

    .auteur
    {
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 100;

      img
      {
        width: 80px;
        border-radius: 50px;

      }

      .name
      {
        color: #fff;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1;
      }

      .date
      {
        @extend .text-subtitle;
        color: $white;
      }
    }

    .content
    {
      position: absolute;
      top: 45%;
      left: 0;
      transform: translate(0, -50%);

      width: 100%;
      height: auto;

      padding-left: 20px;
      padding-right: 20px;

      z-index: 2;

      @media all and (max-width: 767px){
        top: 40%;
      }

      text-align: center;

      .title1
      {
        font-size: 42px;
        margin: 0 auto;
        max-width: 740px;
        color: #fff;
        font-weight: 100;
        line-height: 1.5;
        font-family: $fontTitle;

        @media all and (max-width: 767px){
          font-size: 24px;
          line-height: 1.5;
          padding: 0;
          margin-bottom: 20px;
          max-width: 540px;
        }

        span
        {
          color: $colorAccent1;
        }

      }

    }

  }

  .publication-data-wrapper {
    width: 100%;
    background: $colorAccent3;

    .publication-data {
      width: 100%;
      text-align: justify;
      padding-bottom: 80px;
      margin-bottom: 0;
      background-color: $colorAccent3;

      color: #000;
      font-size: 16px;
      line-height: 30px;

      &:first-of-type{
        padding-top: 0;
      }

      @media all and (max-width: 767px){
        padding: 25px 15px;
        font-size: 16px;
        line-height: 28px;
      }

      .content {
        position: relative;
        z-index: 9;
        max-width: 730px;
        margin: -60px auto 0;
        padding: 50px;
        background-color: $white;
        text-align: left;

        //.lettrine {
        //    /*font-family: $fontHero;*/
        //    font-family: $fontTitle;
        //    color: $colorAccent2;
        //    font-size: 72px;
        //    float: left;
        //    margin-right: 7px;
        //    margin-bottom: 7px;
        //    margin-top: 22px;
        //  }

          b {
            font-weight: 600;
          }

          span, p {
            @extend .main-text;
          }

          h1 {
            @extend .title1;
          }

          h2 {
            @extend .title2;
          }

          h3 {
            @extend .title3;
          }

          h4 {
            @extend .title4;
          }

          h5 {
            @extend .title5;
          }

      }

      @extend %clearfix;

      .article-title{
        background-color: $light;
        color: $colorAccent1;
        margin: 0;
        padding: 15px 20px;
        font-weight: 400;
        text-transform: uppercase;
      }

      p,
      p span,
      .main-text,
      ul li {
        font-family: $fontText;
        color: #000;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
        @media all and (max-width: 767px){
          font-size: 16px;
          line-height: 28px;
        }
      }

      ul li{
        margin: 0;
        padding: 0;
      }

      .sub-title,
      h1,
      h2,
      h3,
      h4
      {
        color: $colorAccent1;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      img
      {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 20px 0;
      }

    }
  }

  .publication-data
  {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;

    color: #000;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      font-family: $fontText;
      color: #000;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;
      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
      a{
        color: $colorAccent3;
      }
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .ingredients
    {
      width: 40%;
      display: inline-block;
      float: left;
      @media all and (max-width: 767px){
        width: 100%;
      }
      .recette-sub-header{
        h2{
          color: $white;
          margin: 0;
        }
      }
      ul
      {
        background-color: $light;
        padding: 25px 25px 15px 45px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
          list-style-type: none;
          position: relative;
          text-align: left;



        }
        li:before
        {
          content: '';
          display: inline-block;
          width: 13px;
          height: 13px;
          background-image: url('../images/check.png');
          background-size: 13px;
          position: absolute;
          top: 5px;
          left: -25px;
        }

      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 5px;

      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }

      .recette-sub-header
      {
        background-color: $light;

        h2
        {
          color: $colorAccent1;
          margin: 0;
        }

        @media all and (max-width: 767px){
          background-color: $colorAccent1;

          h2
          {
            color: #fff;
          }
        }
      }

      ul
      {
        list-style-type: decimal;
        padding: 25px 25px 15px 35px;
        margin: 0;

        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;

          a{
            color: $colorAccent1;
          }
        }
      }
    }

    .recette-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: #fff;

      h2
      {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

  }

  .recette-source {
    margin: 0 30px;

    .source {
      color: $colorAccent3;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background: $light;
    margin: auto 15px;

    &.top {
      margin-bottom: 20px;
    }

    &.bottom {
      margin-top: 20px;
    }
  }
}

