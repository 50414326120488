.bloc-informations{
  position: relative;
  width: 100%;
  height: auto;

  background: $light;

  @extend %clearfix;

  .subjects-list{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30%;
    height: auto;
    /* padding-top: 15px; */
    background: $dark;

    nav a{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding: 35px 65px 35px 90px;
      color: $white;

      .num{
        display: block;

        position: absolute;
        top: 50%;
        left: 25px;
        transform: translate(0, -50%) translateZ(0);
        filter: blur(0);
        backface-visibility: hidden;

        width: 47px;
        height: 47px;
        border: 2px solid $white;
        border-radius: 50%;

        text-align: center;
        line-height: 41px;
        text-indent: 2px;
        font-size: 18px;
        color: $white;
      }

      .title{
        display: block;
        line-height: 1;
        font-weight: 300;
        color: $white;
        font-size: 17px;
      }

      i{
        display: block;

        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate(0, -50%) translateZ(0);
        filter: blur(0);
        backface-visibility: hidden;

        width: 30px;
        height: 30px;

        text-align: center;
        line-height: 28px;
        text-indent: 2px;
        font-size: 11px;
        color: $white;
      }

      &:hover,
      &.active{
        background: $darker;
      }

    }

  }

  .subject-content{
    position: relative;
    float: right;
    width: 70%;
    height: auto;
    padding: 8%;

    overflow: hidden;

    .visual{
      position: absolute;
      top: 15%;
      right: 0;
    }

    article{
      display: none;
      width: 100%;
      margin: 0 0;
      max-width: 875px;
      min-height: 475px;

      .content{
        opacity: 0;
        transform: translate(-60px, 0);
        transition: all 0.35s ease-out;
      }

      &.active{
        display: block;

      }

      .content.active{
        opacity: 1;
        transform: translate(0, 0);
      }

    }

    .main-text{
      margin-bottom: 35px;
      a{
        color: $colorAccent1;
      }
    }

    .site-hr{
      float: left;
    }

  }

  @media only screen and (max-width: 1274px)
  {
    .subjects-list{

      width: 35%;

      nav{
        a{
          .title{
            font-size: 17px;
          }
          .subtitle{
            font-size: 11px;
          }
        }
      }

    }

    .subject-content{

      width: 65%;
      padding: 40px 25px;

      p{
        font-size: 19px;
      }

    }
  }

  @media only screen and (max-width: 1044px)
  {
    .subjects-list{
      width: 40%;

      nav{
        a{
          padding: 20px 45px 20px 55px;

          .title{
            font-size: 13px;
            font-weight: 400;
          }

          .subtitle{
            font-size: 12px;
          }

          .num{
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translate(0, -50%);
            width: 30px;
            height: 30px;
            background: transparent;
            line-height: 25px;
          }

          i{
            right: 15px;
          }
        }
      }

    }

    .subject-content{
      width: 60%;
      h2{
        font-size: 28px;
      }
      p{
        font-size: 15px;
      }
      .site-btn-container{
        padding-top: 15px;
      }
    }
  }

  @media only screen and (max-width: 767px)
  {
    .subjects-list{
      display: block;
      position: relative;
      width: 100%;

      nav a{
        i{
          transform: translate(0, -50%) translateZ(0) rotate(90deg);
        }
      }

    }

    .subject-content{
      float: none;
      width: 100%;

      .circle-effect{
        width: 425px;
        height: 585px;
        border: 45px solid rgba(51, 50, 51, .15);
      }

      article{
        min-height: inherit;
      }
    }
  }

  @media only screen and (max-width: 575px)
  {
    .subject-content{
      padding: 40px 25px;

      h2{
        font-size: 22px;
      }
      p{
        font-size: 14px;
        text-align: justify;
        font-weight: 300;
      }
    }
  }

  @media only screen and (max-width: 374px)
  {
    .subject-content h2 {
      font-size: 19px;
    }
  }

}