
.bloc-infolettre{
  position: fixed;
  left: 0;
  bottom: 49px;
  width: 100%;
  z-index: 1;
  padding: 100px 50px;
  background: $colorAccent4;
  text-align: center;

  .title3{
    @extend .title5;
    color: $white;
    max-width: 530px;
    margin: 25px auto 46px;
  }

  i
  {
    position: relative;
    top: 7px;
    display: inline-block;
    color: $colorAccent2;
    font-size: 53px;
    font-weight: 100;
    line-height: 1;
    height: 33px;
    width: 38px;

    &:before {
      display: none;
    }


    &.icon-infolettre {
      background: url('../images/newsletter-icon.svg') no-repeat center center / 45px;
      background-size: cover;

    }
  }

  .input-infolettre{
    position: relative;
    width: 80%;
    max-width: 380px;
    height: auto;
    margin: 0 auto;
    padding: 0 0;

    .form-field{
      position: relative;
    }

    label, {

      color: $lightGrey;
    }

    input[type="email"]{
      width: 100%;
      padding: 23px 15px;
      border: none;
      outline: none;
      border-radius: 6px;
      background: $colorAccent6;
      color: $lightGrey;
      font-size: 15px;
      font-weight: 400;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $lightGrey;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $lightGrey;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $lightGrey;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $lightGrey;
      }
    }

    [type="submit"]{
      position: absolute;
      top: 7px;
      right: 7px;

      width: 51px;
      height: 51px;

      border: none;
      outline: none;
      border-radius: 3px;
      overflow: hidden;

      color: #fff;
      background: $colorAccent1;
      text-transform: uppercase;

      i{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        font-size: 28px;
        text-align: center;
        color: $white;
        padding-bottom: 0;

        background: url('../images/icon-arrow-right.svg') no-repeat center center;
      }
    }

    .parsley-errors-list{
      position: absolute;
      left: 0;
      bottom: -18px;
      width: 100%;
    }

  }

  @media all and (max-width: 1024px) {
    padding: 40px 20px;
    position: relative;
    bottom: 0;
  }

}
