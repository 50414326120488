.site-content{
  padding-bottom: 442px;
  @media all and (max-width: 1024px) {
    padding-bottom: 0;
  }
}
.main-footer{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;

  width: 100%;
  height: auto;

  padding: 16px 30px;

  background: $colorAccent2;

  border-top: 1px solid #eee;

  @extend %clearfix;

  .icon-share
  {
    width: 30px;
    height: 30px;
    display: inline-block;

    margin-left: 10px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;

    &.instagram {
      background-image: url('../images/instagram.svg')
    }

    &.facebook {
      background-image: url('../images/facebook.svg')
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover
    {
      opacity: .7;
    }
  }

  .main-text{
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $darkGrey;
  }

  .left{
    float: left;

    .main-text {
      @extend .text-caption;
    }
  }

  .right{
    float: right;
    display: flex;
    align-items: center;

    .main-text {
      @extend .main-text;
      margin-right: 20px;
    }
  }

  @media all and (max-width: 1024px) {
    position: relative;
  }

  @media all and (max-width: 850px) {
    .right
    {
      display: none;
    }
  }

  @media all and (max-width: 499px) {
    .left{
      float: none;
      width: 100%;
    }
    .right{
      display: none;
    }
    .main-text{
      width: 100%;
      text-align: center;
    }
  }

}
