.pagination {
  display: flex;
  -js-dispay: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 40px 20px;
  list-style: none;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 767px) {
    padding: 0;
  }

  & > li {
    margin: 0 18px;

    @media (max-width: 767px) {
      margin: 10px 20px;
    }

    &.disabled {
      opacity: 0;
      margin: 0;
    }

    &.active {
      padding: 5px 14px;
      background-color: $colorAccent1;
      color: $white;
      border-radius: 30px;
    }

    & > a {
      @extend .text-subtitle;

      &[rel=prev] {
        margin-right: 14px;
        padding-left: 10px;
        background: url('../images/pagination-arrow-left.svg') no-repeat left center / 12px auto;
        color: transparent;

        @media (min-width: 901px) {
          &:after {
            color: $darkerGrey;
          }
        }

        @media (max-width: 767px) {
          margin: 0;

          &:after {
            display: none;
          }
        }
      }

      &[rel=next] {
        margin-left: 14px;
        padding-right: 10px;
        background: url('../images/pagination-arrow-right.svg') no-repeat right center / 12px auto;
        color: transparent;

        @media (min-width: 901px) {
          &:before {
            color: $darkerGrey;
          }
        }

        @media (max-width: 767px) {
          margin: 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.fr .pagination > li > a {
  &[rel=prev]:after {
    content: 'PRÉC';
  }
  &[rel=next]:before {
    content: 'SUIV';
  }
}

.en .pagination > li {
  &[rel=prev]:after {
    content: 'PREV';
  }
  &[rel=next]:before {
    content: 'NEXT';
  }
}
